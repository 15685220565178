import { Popover } from 'bootstrap';

window.bs5validation = {
    UseToolTop: true,
    ValFieldContainer: '.form-group, .input-group, .val-group',
    ValFieldSelector: '.form-control, .form-select',
    ValMarkedSelector: '.alert-warning',
    ValidateAsYouCompleteFields: true,
    PopoverTrigger: "hover focus",
    ValidatedClass: "was-validated",
    ValidControlClass: "is-valid",
    InValidControlClass: "is-invalid",
    SpinnerClear: null,
    Init: function () {
    },
    ValidateButtonClick: function (btn) {
        this.AddLoader(btn);
        if (this.validateFrm(btn))
            return true;
        this.ClearSpinners();
        return false;
    },
    StopValidationSpinners: function () {
        this.ClearSpinners();
    },
  
    ClearSpinners: function () {
        document.querySelectorAll('.btn .fa-spinner').forEach(spinner => {
            spinner.remove();
        });
        if (this.SpinnerClear !== undefined)
            clearTimeout(this.SpinnerClear);
    },
    AddLoader: function (targ) {
        if (targ.querySelector('.fa-spinner') === null) {
            targ.insertAdjacentHTML('beforeend', '<i class="fas fa-spinner fa-spin ms-2"></i>');
        }
        if (this.SpinnerClear !== undefined)
            clearTimeout(this.SpinnerClear);
        this.SpinnerClear = setTimeout(() => this.ClearSpinners(), 5000);
    },
    validateFrm: function (submit) {
        const submitButton = typeof submit === 'string' ? document.querySelector(submit) : submit;
        const valtarg = submitButton.dataset.valtarg;
        const ValFrm = valtarg ? document.querySelector(valtarg) : submitButton.closest('[role="form"]');

        const Inputs = ValFrm.querySelectorAll(this.ValFieldSelector);
        let ErrorCount = 0;
        
        Inputs.forEach(input => {
            const blurHandler = () => {
                bs5validation.ValidateFrmField(input);
            };
            if (this.ValidateAsYouCompleteFields) {
                input.removeEventListener('blur', blurHandler);
                input.addEventListener('blur', blurHandler);
            }
            ErrorCount += bs5validation.ValidateFrmField(input);
        });

        if (ErrorCount > 0) {
            submitButton.classList.add('has-errors');
            this.ClearSpinners();
        } else {
            submitButton.classList.remove('has-errors');
        }
        return ErrorCount === 0;
    },
    ValidateFrmField: function (input) {
        const ValCtrl = typeof input === 'string' ? document.querySelector(input) : input;
        const FormGroup = ValCtrl.closest(this.ValFieldContainer);

        if (ValCtrl.closest('.skipval') || (!ValCtrl.offsetParent && !ValCtrl.classList.contains('validatehidden'))) {
            return 0;
        }

        let error = "";
        const StrVal = ValCtrl.value || "";
        const CustomReq = ValCtrl.getAttribute('data-customreq');
        const CustomOther = ValCtrl.getAttribute('data-errormessage');

        if (ValCtrl.required || ValCtrl.hasAttribute("required")) {
            if (StrVal === "") {
                error = CustomReq || "This field is required";
            }
        }
        if ((ValCtrl.type === "date" || ValCtrl.classList.contains("date")) && StrVal !== "") {
            if (!this.isDate(StrVal)) {
                error = CustomOther || "This field requires a date format please enter in this format yyyy-mm-dd";
            }
        }
        if (ValCtrl.classList.contains("int") && StrVal !== "") {
            var Intvalue = Number(StrVal);
            if (!(Math.floor(Intvalue) === Intvalue && !isNaN(Intvalue))) {
                error = CustomOther ? CustomOther : "This field requires a whole number (no decimals)";
            }
        }
        if (ValCtrl.type === "number" || ValCtrl.classList.contains("numeric") && StrVal !== "") {
            if (isNaN(StrVal)) {
                error = CustomOther ? CustomOther : "This field requires a numeric value";
            }
        }
        if (ValCtrl.classList.contains("dec") && StrVal !== "") {
            var regexp = /^[0-9]+([\,\.][0-9]+)?$/g;
            if (!regexp.test(StrVal)) {
                error = CustomOther ? CustomOther : "This field requires a number e.g. 12.99";
            }
        }
        if ((ValCtrl.type === "tel" || ValCtrl.classList.contains("phone")) && StrVal !== "") {
            var pre = /([0-9]|-|\s|\+|\(|\)){8,20}/;
            if (!pre.test(StrVal)) {
                error = CustomOther ? CustomOther : "Please enter a valid phone number";
            }
        }
        if ((ValCtrl.type === "email" || ValCtrl.classList.contains("eml")) && StrVal !== "") {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(StrVal)) {
                error = CustomOther ? CustomOther : "Please enter a valid email address";
            }
        }
        if (ValCtrl.type === "url" || ValCtrl.classList.contains("url") && StrVal !== "") {
            if (StrVal.startsWith("http://") === false && StrVal.startsWith("https://") === false) {
                StrVal = "http://" + StrVal;
                ValCtrl.value = StrVal;
            }
            if (StrVal.indexOf('localhost:') === -1) {
                if (!is_valid_url(StrVal)) {
                    error = CustomOther ? CustomOther : "Please enter a valid url";
                }
            }
        }
        if (ValCtrl.hasAttribute('minlength') && StrVal !== "") {
            if (StrVal.length < parseInt(ValCtrl.getAttribute('minlength'))) {
                error = CustomOther ? CustomOther : "Please enter at least " + ValCtrl.getAttribute('minlength') + " characters";
            }
        }
        if (ValCtrl.hasAttribute('minamount') && StrVal !== "") {
            if (parseFloat(ValCtrl.value) < parseFloat(ValCtrl.getAttribute('minamount'))) {
                error = CustomOther ? CustomOther : "Please enter a value of at least " + ValCtrl.attr('minamount');
            }
        }
        if (ValCtrl.classList.contains("terms") && ValCtrl.prop('checked') === false) {
            error = CustomOther ? CustomOther : "You must agree to the terms";
            PopoverTrigger = "hover";
        }

       // FormGroup.classList.add(this.ValidatedClass);

        let popover = Popover.getInstance(ValCtrl);
        if (popover) {
            popover.dispose();
        }

        if (error !== "") {
            ValCtrl.classList.add(this.InValidControlClass);
            ValCtrl.classList.remove(this.ValidControlClass);
            if (this.UseToolTop) {
                popover = new Popover(ValCtrl, {
                    content: error,
                    trigger: this.PopoverTrigger
                });
            }
            return 1;
        }
        else {
            ValCtrl.classList.remove(this.InValidControlClass);
            ValCtrl.classList.add(this.ValidControlClass);
        }

        return 0;
    },
    is_valid_url: function (url) {
        return url.match(/^(ht|f)tps?:\/\/[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/);
    },
    isDate: function(txtDate) {
        var currVal = txtDate;
        if (currVal === '')
            return false;

        var rxDatePattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/; //Declare Regex
        var dtArray = currVal.match(rxDatePattern); // is format OK?

        if (dtArray === null)
            return false;

        //Checks for mm/dd/yyyy format.
        var dtMonth = dtArray[3];
        var dtDay = dtArray[5];
        var dtYear = dtArray[1];

        if (dtMonth < 1 || dtMonth > 12)
            return false;
        else if (dtDay < 1 || dtDay > 31)
            return false;
        else if ((dtMonth === 4 || dtMonth === 6 || dtMonth === 9 || dtMonth === 11) && dtDay === 31)
            return false;
        else if (dtMonth === 2) {
            var isleap = (dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0));
            if (dtDay > 29 || (dtDay === 29 && !isleap))
                return false;
        }
        return true;
    }
};

bs5validation.Init();
import { Dropdown, Collapse, Carousel } from 'bootstrap';
import AOS from 'aos';
//dev note - Use custom bs-data-dropdown etc to avoid conflicts in CMS js.
window.addEventListener('scroll', () => document.body.classList[window.scrollY > 0 ? 'add' : 'remove']('scrolled'));
AOS.init();

/////not needed as bootstrap automatically adds carousel
//const myCarouselElement = document.querySelector('#testimonials-slider');
//if (myCarouselElement != null) {
//    const carousel = new Carousel(myCarouselElement, {
//        interval: 2000
//    });
//}



//Blazor.addEventListener('enhancedload', () => {
//    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
//});

//function called from JSInterop when page first loads or navigation changes (after render)
window.PageLoaded = function (firstLoad) {
    if (firstLoad) {
        
    }
    updateElementPosition();
    setupSectionScrolling();
    hideBSMenu();
}

function hideBSMenu() {
    var collapseElement = document.getElementById("navbarCollapse");
    var collapseInstance = Collapse.getInstance(collapseElement);
    if (collapseInstance && collapseElement.classList.contains('show')) {
        collapseInstance.hide();
    }
}
function updateElementPosition() {

    const fixedElement = document.querySelector('.fixed-element');
    if (fixedElement == null)
        return;
    if (window.innerWidth < 992) {
        fixedElement.style.position = '';
        fixedElement.style.top = 'auto';
        fixedElement.style.bottom = '0'; // Adjust based on container padding
        fixedElement.style.width = '100%'; // Let it naturally fill the container
        return;
    }

    const container = document.querySelector('.fixed-column');
    const mainNav = document.querySelector('.main-nav');

    const navHeight = mainNav.offsetHeight + 20;
    const containerRect = container.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const elementHeight = fixedElement.offsetHeight;
    const offsetTop = container.offsetTop - navHeight; // Account for nav height
    const containerHeight = container.offsetHeight;

    // Calculate the bottom of the container from the top of the document
    const containerBottomFromTop = offsetTop + containerHeight + navHeight;

    if (scrollTop > offsetTop && scrollTop + elementHeight + navHeight < containerBottomFromTop) {
        fixedElement.style.position = 'fixed';
        fixedElement.style.top = `${navHeight}px`; // Position below the nav
        fixedElement.style.bottom = '';
        fixedElement.style.width = `${container.clientWidth}px`; // Match the container's width
    } else if (scrollTop + elementHeight + navHeight >= containerBottomFromTop) {
        // When bottom of the container is at or above the fixed element's height from bottom of the viewport
        fixedElement.style.position = 'absolute';
        fixedElement.style.top = 'auto';
        fixedElement.style.bottom = '0'; // Adjust based on container padding
        fixedElement.style.width = '100%'; // Let it naturally fill the container
    } else {
        // Revert to original state
        fixedElement.style.position = 'absolute';
        fixedElement.style.top = '0';
        fixedElement.style.bottom = '';
        fixedElement.style.width = '100%'; // Let it naturally fill the container
    }
}
window.addEventListener('scroll', updateElementPosition);
window.addEventListener('resize', updateElementPosition);

function setupSectionScrolling() {
    const scrollToNextSection = (ele) => {
        let parent = ele.closest('section') || ele.closest('.landing-page-container');
        let target = parent.nextElementSibling;
        let menuHeight = document.querySelector('.main-nav').offsetHeight;

        if (target) {
            window.scrollTo({
                top: target.offsetTop - menuHeight,
                behavior: 'smooth'
            });
        }
    };
    document.querySelectorAll(".scroller, a[href*='#next']").forEach(element => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
            scrollToNextSection(element);
        });
    });
}


window.sessionJS = {
    GetClientInfo: function () {
        return {
            ViewportSize: `${window.innerWidth}x${window.innerHeight}`,
            ScreenResolution: `${screen.width}x${screen.height}`,
            PageTitle: document.title,
            Width: window.innerWidth
        };
    },
    ClickTracker: null,
    ClickSetup: false,
    SetupClickTrack: function (ClickTracker) {
        this.ClickTracker = ClickTracker;
        if (this.ClickSetup)
            return;
        this.ClickSetup = true;
        const body = document.body;
        body.addEventListener('click', function (event) {
            if (event.target.matches('a[href^="mailto:"]')) {
                ClickTracker.invokeMethodAsync('LogLinkClick', 'email').catch(error => console.error(error));
            }
        });
        body.addEventListener('click', function (event) {
            if (event.target.matches('a[href^="tel:"]')) {
                ClickTracker.invokeMethodAsync('LogLinkClick', 'telephone').catch(error => console.error(error));
            }
        });
    },
    PageLoaded: function (firstRender, ClickTracker) {
        if (window.PageLoaded !== undefined)
            window.PageLoaded(firstRender);
        if (firstRender && ClickTracker !== undefined)
            this.SetupClickTrack(ClickTracker);
        return this.GetClientInfo();
    },
    PageUpdated: function () {
        if (window.PageUpdated !== undefined)
            window.PageUpdated();
        return this.GetClientInfo();
    }
};
